import { UNIT_TYPE_OPTIONS } from "../constants/common";

import { SelectOptions } from "~/components/atoms/SelectBox/SingleSelect";

export const convertUnitTypeOptions = (
  options: SelectOptions[]
): SelectOptions[] => [
    ...(UNIT_TYPE_OPTIONS ?? []),
    ...(options ?? []).filter(
      (option) => option?.bedRoomCount === "0" && option?.isStudio === "false"
    ),
  ];
